/* Global Styles */
.containerAll {
  font-family: 'Times New Roman', Times, serif;
}

/* Hero Section */
.heroSection {
  position: relative;
}

.heroImage {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.heroText,
.heroTitle {
  position: absolute;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 1.35rem;
  text-shadow: 3px 3px 12px black;
  opacity: 0;
  animation: fade-in 3s forwards;
}

.heroText1 {
  top: 55%;
  left: 67%;
  width: 60%;
  animation-delay: 4s;
}

.heroText2 {
  top: 72%;
  left: 67%;
  width: 60%;
  animation-delay: 5s;
}

.heroText3 {
  top: 85%;
  left: 67%;
  width: 60%;
  animation-delay: 6s;
}

.heroTitle {

  top: 10%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 3rem;
  animation-duration: 2s;
  animation-delay: 1s;
}



@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {

  .heroTitle {
    font-size: 2.75rem;
    padding-top: 5%;
  }

  .heroText {
    font-size: 12px;
    font-weight: 700;
    top: 54%;
    animation-delay: 3s;
  }

  .heroText2 {
    font-size: 12px;
    font-weight: 700;
    top: 63%;
    animation-delay: 6s;
  }

  .heroText3 {
    font-size: 0.7rem;
    font-weight: 700;
    top: 72%;
    animation-delay: 8s;
  }


}

@media screen and (max-width: 500px) {

  .heroText {
    top: 54%;
  }

  .heroText2 {
    top: 67%;
  }

  .heroText3 {
    top: 78%;
  }
}

@media screen and (max-width: 434px) {

  .heroText {
    top: 42%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .heroText2 {
    top: 62%;
  }

  .heroText3 {
    top: 78%;
  }
}

.orange-background {
  position: fixed;
  bottom: 0%;
  width: 100%;

  background: rgb(253, 117, 53);
  background: linear-gradient(135deg, rgba(253, 117, 53, 1) 0%, rgba(253, 138, 59, 1) 20%, rgba(253, 179, 62, 1) 40%, rgba(253, 221, 64, 1) 60%, rgba(247, 224, 19, 1) 80%, rgba(252, 176, 69, 1) 100%);
}

.orange-background.home-page {
  position: fixed !important;
  bottom: 0;
}

.orange-background:not(.home-page) {
  top: 0;
}


/* About Section */
.aboutPage {
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
}

.aboutContainer {
  padding-top: 45%;
  padding: 47% 4% 0 4%;
}

.aboutTitle {
  text-align: center;
  font-size: 3em;
  padding-bottom: 3%;
}

.aboutText {
  font-size: 1.25em;
}

@media screen and (min-width: 1120px) {
  .aboutContainer {
    padding-top: 40%;
  }
}

@media screen and (min-width: 1310px) {
  .aboutContainer {
    padding-top: 35%;
  }
}


/* Image Slider */
.sliderContainer {
  text-align: center;
  position: relative;
  height: 60%;
  max-height: 600px;
}

.sliderImage {
  width: 60%;
  max-width: 600px;
  height: auto;
  border-radius: 20%;
  position: relative;
  margin-top: 5%;
}

.slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.slide.active {
  opacity: 1;
}

.left-arrow,
.right-arrow {
  position: absolute;
  font-size: 2rem;
  top: 33%;
  transform: translateY(-50%);
}

.left-arrow {
  left: 10%;
}

.right-arrow {
  right: 10%;
}

@media screen and (max-width: 768px) {
  .left-arrow {
    position: absolute;
    font-size: 2rem;
    top: 25%;
    left: 10%;
    transform: translateY(-50%);
  }

  .right-arrow {
    position: absolute;
    font-size: 2rem;
    top: 25%;
    right: 10%;
    transform: translateY(-50%);
  }
}

/* Projects Section */
.ProjectsContainer {
  display: flex;
  flex-direction: column;
  min-height: 110vh;
}

.ProjectsTitle {
  text-align: center;
  font-size: 3rem;
  color: rgb(253, 117, 53);
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  grid-auto-rows: 32rem;
}

.card {
  cursor: pointer;
  background-color: transparent;
  height: 40vh;
  perspective: 1000px;
}

.cardP {
  font-size: .85rem;
}

.card h1 {
  font-size: 25px;
  border-bottom: 1px #fff solid;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.card-back {
  background-color: #333;
  color: white;
  transform: rotateY(180deg);
  padding-right: 5%;
}

.card li {
  list-style: none;
  padding-bottom: 10px;
}

@media (max-width: 800px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 1rem;
  }
}

@media (max-width: 650px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
  }
}

@media (max-width: 434px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
  }
}

/* Resume Section */
.orange-bg {
  background-color: rgb(253, 117, 53);
  color: black;
}

.orange {
  color: rgb(253, 117, 53);
}

.tableTitle {
  font-size: 1.1rem;
}

.resumeButton,
.resumeButton-orange {
  color: #fff;
  padding: 10px 20px;
  border-radius: 13px;
  font-size: 16px;
  margin-bottom: 1%;
}

.resumeButton {
  background-color: #007bff;
}

.resumeButton-orange {
  background-color: #ff9901;
  color: black;
}

/* Skill Section */
.skills-h2 {
  color: black;
}

.btn-link {
  color: rgb(253, 117, 53);
}


/* ContactForm */
#contact {
  background-color: white;
  padding: 5% 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Style for the contact section header */
#contact h1 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  border-bottom: 2px solid black;
}

#contact h5 {
  font-size: 36px;
  margin: 30px 0px;
  text-align: center;
  border-bottom: 2px solid black;
}

/* Style for the social media buttons */
#contact .btn-social-icon {
  background-color: #555;
  border-radius: 50%;
  color: #fff;
  font-size: 50px;
  width: auto;
  text-align: center;
  transition: all 0.3s ease-in-out;
  margin: 3%;
}

/* Hover state for social media buttons */
#contact .btn-social-icon:hover {
  background-color: rgb(253, 117, 53);
  color: #fff;
  transform: translateY(-5px);
}

/* Style for the phone and email links */
#contact .btn-links-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#contact .btn-link {
  color: #555;
  font-size: 2rem;
  padding: 10px 20px;
  margin-top: 20px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

/* Hover state for phone and email links */
#contact .btn-link:hover {
  color: rgb(253, 117, 53);
  transform: translateY(-5px);
}

/* Media query for small screens */
@media (max-width: 768px) {
  #contact h1 {
    font-size: 30px;
  }

  #contact .btn-link {
    font-size: 20px;
  }
}

/* Footer */
.footer {

  width: 100%;
  background-color: black;
  color: white;
  text-align: center;

}

.footer-icon {
  font-size: 3rem;
  color: #555;
  border-radius: 20%;
}

.footer-icon:hover {
  background-color: rgb(253, 117, 53);
  color: white;
  font-size: 3.5rem;
  transform: translateY(-5px);

}

.footer .footer-link {
  color: rgb(253, 117, 53);
}

.footer .footer-link .blue {
  color: rgb(253, 117, 53);
}

.footer-link:hover {
  transform: translateY(-5px);
}

.footer-h5 {
  padding-bottom: 10%;
}

.blue {
  color: rgb(253, 117, 53);
}

/* Landscape Responsive */
@media screen and (max-width: 1000px) and (max-height: 645px) and (orientation: landscape) {
  .heroTitle {
    font-size: 2.75rem;
  }

  .heroText {
    font-size: 1em;

  }

  .heroText1,
  .heroText2,
  .heroText3 {
    width: 40%;
  }

  .heroText1 {
    top: 32%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .heroText2 {
    top: 60%;
  }

  .heroText3 {
    top: 77%;
  }

  /* Add any other necessary styles for landscape view */
}

@media screen and (max-width: 1300px) and (max-height: 690px) {
  .heroTitle {
    font-size: 1.5rem;
  }

  .heroText {
    font-size: 12px;

  }

  .heroText1,
  .heroText2,
  .heroText3 {
    width: 40%;
  }

  .heroText1 {
    top: 32%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .heroText2 {
    top: 60%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .heroText3 {
    top: 68%;
    left: 25%;
  }

  .resumeButton-orange {
    padding: 5px 5px;
  }

  /* Add any other necessary styles for landscape view */
}

@media screen and (max-width: 277px) and (max-height: 678px) {
  .heroTitle {
    font-size: 1.25rem;
  }

  .heroText {
    font-size: 12px;
  }

  .heroText1,
  .heroText2,
  .heroText3 {
    width: 60%;
  }

  .heroText1 {
    top: 25%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .heroText2 {
    top: 52%;
  }

  .heroText3 {
    top: 72%;
    font-size: 1rem;
  }

  .resumeButton-orange {
    padding: 5px 5px;
  }
}